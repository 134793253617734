import { mergeStyles, PrimaryButton, Spinner, Stack, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Input } from '../types';
import { contextualFormStyles } from './ContextualFormStyles';
import Validator from 'validator';
import TickIcon from '../../assets/validate-tick.png';
import ErrorIcon from '../../assets/validate-error.png';

const noValidateIcon = 'none';
const validateTickIcon = `url(${TickIcon})`;
const validateErrorIcon = `url(${ErrorIcon})`;

export const ContextualForm = (props: {
  inputs: Input[];
  onSubmit?: (inputs: Input[]) => Promise<void>;
  isMobile: boolean;
  above18: string;
  submitLoading?: boolean;
}): JSX.Element => {
  const { inputs, onSubmit, isMobile, above18, submitLoading } = props;
  const [inputValues, setInputValues] = useState<Array<Input>>(inputs);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formValidation, setFormValidation] = useState(true);
  const [mobileConsent, setMobileConsent] = useState(false);
  const [emailConsent, setEmailConsent] = useState(false);
  const [firstNameValidatorIcon, setFirstNameValidatorIcon] = useState(noValidateIcon);
  const [lastNameValidatorIcon, setLastNameValidatorIcon] = useState(noValidateIcon);
  const [phoneValidatorIcon, setPhoneValidatorIcon] = useState(noValidateIcon);
  const [emailValidatorIcon, setEmailValidatorIcon] = useState(noValidateIcon);
  const [zipcodeValidatorIcon, setZipcodeValidatorIcon] = useState(noValidateIcon);

  const theme = useTheme();
  const styles = contextualFormStyles(theme);

  useEffect(() => {
    if (!formValidation && mobileConsent && emailConsent) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [formValidation, mobileConsent, emailConsent]);

  const defaultOnChange = (key: string, value: string) => {
    for (let i = 0; i < inputValues.length; i++) {
      if (inputValues[i].key === key) {
        inputValues[i].value = value;
      }
    }

    // Validator Icons
    switch (key) {
    case 'FIRST_NAME':
      if (!value || value === '') {
        setFirstNameValidatorIcon(noValidateIcon);
      } else if (Validator.isAlpha(value, 'en-US', { ignore: ' .' })) {
        setFirstNameValidatorIcon(validateTickIcon);
      } else {
        setFirstNameValidatorIcon(validateErrorIcon);
      }
      break;
    case 'LAST_NAME':
      if (!value || value === '') {
        setLastNameValidatorIcon(noValidateIcon);
      } else if (Validator.isAlpha(value, 'en-US', { ignore: ' .' })) {
        setLastNameValidatorIcon(validateTickIcon);
      } else {
        setLastNameValidatorIcon(validateErrorIcon);
      }
      break;
    case 'PHONE':
      if (!value || value === '') {
        setPhoneValidatorIcon(noValidateIcon);
      } else if (Validator.isMobilePhone(value, 'en-US')) {
        setPhoneValidatorIcon(validateTickIcon);
      } else {
        setPhoneValidatorIcon(validateErrorIcon);
      }
      break;
    case 'EMAIL':
      if (!value || value === '') {
        setEmailValidatorIcon(noValidateIcon);
      } else if (Validator.isEmail(value)) {
        setEmailValidatorIcon(validateTickIcon);
      } else {
        setEmailValidatorIcon(validateErrorIcon);
      }
      break;
    case 'ZIPCODE':
      if (!value || value === '') {
        setZipcodeValidatorIcon(noValidateIcon);
      } else if (Validator.isPostalCode(value, 'US')) {
        setZipcodeValidatorIcon(validateTickIcon);
      } else {
        setZipcodeValidatorIcon(validateErrorIcon);
      }
      break;
    default:
      break;
    }

    // Validate Input values
    let validInputs = true;
    inputValues.forEach((input) => {
      if (!input.value || input.value === '') {
        validInputs = false;
      }
      if (input.validator) {
        switch (input.validator) {
        case 'name':
          validInputs = validInputs && Validator.isAlpha(input.value, 'en-US', { ignore: ' .' });
          break;
        case 'email':
          validInputs = validInputs && Validator.isEmail(input.value);
          break;
        case 'phone':
          validInputs = validInputs && Validator.isMobilePhone(input.value, 'en-US');
          break;
        case 'zipcode':
          validInputs = validInputs && Validator.isPostalCode(input.value, 'US');
          break;
        default:
          break;
        }
      }
    });

    setFormValidation(!validInputs);
    setInputValues(inputValues);
  };

  const handleSubmit = () => {
    console.log('Submitting Form');
    onSubmit && onSubmit(inputValues);
  };

  return (
    <Stack horizontalAlign="center" style={{ width: '80%' }}>
      <Stack style={{ width: `${isMobile ? '140%' : '60%'}` }}>
        <ul className={mergeStyles(styles.ul)}>
          {inputs.map((input) => {
            return (
              <li key={input.id}>
                <Stack horizontal>
                  <Stack style={{ width: '100%' }}>
                    {input.key === 'FIRST_NAME' && (
                      <input
                        className={mergeStyles(styles.inputField)}
                        type={input.type}
                        name={input.name}
                        defaultValue={input.value}
                        placeholder={input.name}
                        onChange={(value) => {
                          defaultOnChange(input.key, value.target.value);
                        }}
                        style={{
                          backgroundImage: `${firstNameValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                      />
                    )}
                    {input.key === 'LAST_NAME' && (
                      <input
                        className={mergeStyles(styles.inputField)}
                        type={input.type}
                        name={input.name}
                        defaultValue={input.value}
                        placeholder={input.name}
                        onChange={(value) => {
                          defaultOnChange(input.key, value.target.value);
                        }}
                        style={{
                          backgroundImage: `${lastNameValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                      />
                    )}
                    {input.key === 'PHONE' && (
                      <input
                        className={mergeStyles(styles.inputField)}
                        type={input.type}
                        name={input.name}
                        defaultValue={input.value}
                        placeholder={input.name}
                        onChange={(value) => {
                          defaultOnChange(input.key, value.target.value);
                        }}
                        style={{
                          backgroundImage: `${phoneValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                        maxLength={10}
                      />
                    )}
                    {input.key === 'ZIPCODE' && (
                      <input
                        className={mergeStyles(styles.inputField)}
                        type={input.type}
                        name={input.name}
                        defaultValue={input.value}
                        placeholder={input.name}
                        onChange={(value) => {
                          defaultOnChange(input.key, value.target.value);
                        }}
                        style={{
                          backgroundImage: `${zipcodeValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                      />
                    )}
                    {input.key === 'EMAIL' && (
                      <input
                        className={mergeStyles(styles.inputField)}
                        type={input.type}
                        name={input.name}
                        defaultValue={input.value}
                        placeholder={input.name}
                        onChange={(value) => {
                          defaultOnChange(input.key, value.target.value);
                        }}
                        style={{
                          backgroundImage: `${emailValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </li>
            );
          })}
        </ul>
      </Stack>
      <Stack
        horizontal
        horizontalAlign="start"
        verticalAlign="start"
        style={{
          fontSize: '16px',
          fontWeight: '400',
          width: '150%',
          paddingTop: '1rem',
        }}
      >
        <input
          key="MOBILE_CONSENT"
          type="checkbox"
          name="Mobile Consent"
          checked={mobileConsent}
          onChange={() => setMobileConsent(!mobileConsent)}
          style={{ marginRight: '15px', padding: 8, minWidth: '19px', minHeight: '19px' }}
        />
        <span style={{ textAlign: 'start' }}>
          By providing your mobile number, you agree to receive an average of 3 SMS communications
          per month from Noven Pharmaceuticals related to the XELSTRYM Copay Savings Program,
          including educational materials, information about our program, refill reminders,
          marketing messages, and/or market surveys. Messages are subject to the terms of our{' '}
          <a
            href="https://www.noven.com/privacy-
            policy/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486
            .1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp
            =1383244671"
            style={{ color: '#509E2F', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href=" https://www.noven.com/terms-of-
            use/?__hstc=66069809.9ab3ce9c106796b92ee490da65a53011.1704481469615.1
            704481469615.1704481469615.1&__hssc=66069809.1.1704481469615&__hsfp=
            1354570303"
            style={{ color: '#509E2F', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          . Message and data rates may apply. You may opt out of these messages at any time in the
          future by replying STOP. For assistance or questions, please reply HELP.
        </span>
      </Stack>
      <Stack
        horizontal
        horizontalAlign="start"
        verticalAlign="start"
        style={{
          fontSize: '16px',
          fontWeight: '400',
          minWidth: '150%',
          paddingTop: '1rem',
        }}
      >
        <input
          key="EMAIL_CONSENT"
          type="checkbox"
          name="Email Consent"
          checked={emailConsent}
          onChange={() => setEmailConsent(!emailConsent)}
          style={{
            marginRight: '15px',
            padding: 8,
            minWidth: '19px',
            minHeight: '19px',
            top: 0,
          }}
        />
        <span style={{ textAlign: 'left' }}>
          By providing your email address, you agree and acknowledge that you would like to receive
          relevant email communications from Noven Therapeutics, Inc. related to the XELSTRYM Copay Savings Program. The
          information pertaining to you that we collect will be used in accordance with our{' '}
          <a
            href="https://www.noven.com/privacy-
            policy/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486
            .1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp
            =1383244671"
            style={{ color: '#509E2F', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          . If you wish to stop receiving communications in the future, you may unsubscribe, while
          still continuing to use the savings card.
        </span>
      </Stack>
      <Stack>
        <PrimaryButton
          disabled={above18 === 'yes' ? submitDisabled : true}
          className={mergeStyles(styles.button)}
          styles={{
            rootDisabled: {
              backgroundColor: '#fff',
              color: '#999',
            },
          }}
          onClick={() => handleSubmit()}
        >
          {submitLoading ? (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              style={{
                height: 'inherit',
                width: 'inherit',
                color: 'inherit',
                background: 'inherit',
                minWidth: '10vw',
              }}
            >
              <Spinner />
            </Stack>
          ) : (
            <span style={{ fontFamily: 'Centra No2 Bold' }}>Submit</span>
          )}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};
