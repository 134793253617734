import { Stack, ThemeProvider } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import { XelstrymTheme } from './Xelstrym.theme';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { termsConditionsJSX } from './Xelstrym.web';

export const TermsAndConditions = () => {
  const [isMobile, setIsMobile] = useState(false);

  const xelstrymTheme = {
    ...XelstrymTheme,
    fonts: {
      ...XelstrymTheme.fonts,
    },
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const device = parser.getDevice().type;

    const isMobileDevice = device === 'mobile';
    const isSmallScreen = window.innerWidth <= 1000;

    setIsMobile(isMobileDevice || isSmallScreen);
  }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider
      theme={xelstrymTheme}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #E2F0FA 0%, #FFF 100%)',
      }}
    >
      <Header isMobile={isMobile} />
      {isMobile && <Stack style={{ width: '100%', height: '3.5rem', background: '#509E2F' }}></Stack>}
      <Stack horizontalAlign="center" verticalAlign="center">
        <Stack
          horizontalAlign="center"
          verticalAlign="space-evenly"
          style={{
            textAlign: 'center',
            width: `${isMobile ? '90%' : '60%'}`,
            height: 'fit-content',
            background: '#ffffff',
            color: '#54555A',
            // padding: `${isMobile ? '0.8rem' : '1.3rem'}`,
            marginBottom: '4rem',
            marginTop: `${isMobile ? '5rem' : '5rem'}`,
            fontSize: '16px',
            fontWeight: '400',
            // paddingBottom: '1rem',
            paddingTop: `${isMobile ? '2rem' : '2rem'}`,
            border: '1px solid #509E2F',
            borderRadius: '15px',
          }}
        >
          <Stack>
            <span
              style={{
                fontWeight: '700',
                fontSize: `${isMobile ? '28px' : '42px'}`,
                fontFamily: 'Centra No2 Bold',
                padding: '1rem'
              }}
            >
              TERMS & CONDITIONS
            </span>

            <Stack
              style={{
                paddingTop: '1rem',
                paddingLeft: `${isMobile ? '2rem' : '5rem'}`,
                paddingRight: `${isMobile ? '2rem' : '5rem'}`,
                lineHeight: '19px',
              }}
            >
              <span
                style={{
                  fontSize: `${isMobile ? '14px' : '14px'}`,
                  fontWeight: '400',
                  fontFamily: 'Centra No2',
                  textAlign: 'left',
                }}
              >
                {termsConditionsJSX()}
              </span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Footer isMobile={isMobile} />
    </ThemeProvider>
  );
};
