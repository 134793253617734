import {
  Stack,
  useTheme,
  Modal,
  PrimaryButton,
  ResponsiveMode,
  mergeStyles,
  Link,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import CloseModal from '../assets/close-modal.png';
import { InlineModal } from './InlineModal';
import { XelstrymTheme } from '../xelstrym/Xelstrym.theme';
import { InlineModalMobile } from './InlineModalMobile';
import { termsConditionsJSX } from '../xelstrym/Xelstrym.web';

export const EnrollWithoutCard = (props: {
  children: React.ReactNode;
  isMobile: boolean;
  isOpen: boolean;
  handleClose: () => void;
}): JSX.Element => {
  const { children, isMobile, isOpen, handleClose } = props;
  const theme = useTheme();
  const [isModalOpen] = React.useState(isOpen);
  const widthMobile = Math.min(window.innerWidth, window.outerWidth) * 0.8;
  const widthDesktop = '660px';

  useEffect(() => {
    // Diasble body scroll when Modal open
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Enable body scroll when Modal unmounts
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        responsiveMode={ResponsiveMode.small}
        allowTouchBodyScroll={true}
        styles={{
          main: {
            borderRadius: theme.effects.roundedCorner6,
            height: 'auto',
            background: '#ffffff',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            width: `${isMobile ? widthMobile : widthDesktop}`,
          },
        }}
      >
        <div
          className={mergeStyles({
            width: 'inherit',
            borderRadius: `${theme.effects.roundedCorner6} ${theme.effects.roundedCorner6} 0 0`,
            background: '#ffffff',
          })}
        >
          {isMobile ? (
            <>
              <img
                src={CloseModal}
                style={{
                  cursor: 'pointer',
                  position: 'sticky',
                  float: 'right',
                  width: '34px',
                  height: '34px',
                  padding: '0.2rem',
                }}
                onClick={handleClose}
              />
              <br />
              <br />
              <Stack horizontal horizontalAlign="center">
                <span
                  style={{
                    fontFamily: 'Centra No2',
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#0047BB',
                  }}
                >
                  {children}
                </span>
              </Stack>
            </>
          ) : (
            <>
              <img
                src={CloseModal}
                style={{
                  cursor: 'pointer',
                  position: 'sticky',
                  float: 'right',
                  width: '44px',
                  height: '44px',
                  padding: '0.3rem',
                }}
                onClick={handleClose}
              />
              <br />
              <Stack horizontal horizontalAlign="center">
                <span
                  style={{
                    fontFamily: 'Centra No2',
                    fontSize: '48px',
                    fontWeight: '700',
                    color: '#0047BB',
                    paddingTop: '1rem',
                    marginLeft: '3rem',
                  }}
                >
                  {children}
                </span>
              </Stack>
            </>
          )}
          <Stack
            style={{
              color: '#333',
              padding: `${isMobile ? '0.4rem' : '1rem'}`,
              maxHeight: 'fit-content',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              fontSize: `${isMobile ? '16px' : '20px'}`,
              fontWeight: '400',
              justifyContent: 'center',
            }}
          >
            <Stack horizontalAlign="center" verticalAlign="center" style={{ textAlign: 'center' }}>
              <span>Your details have been noted.</span>
              <Stack style={{ paddingTop: '1rem' }}>
                <span
                  style={{
                    fontSize: `${isMobile ? '15px' : '20px'}`,
                    fontWeight: '700',
                    fontFamily: 'Centra No2',
                    paddingLeft: `${isMobile ? '0.3rem' : '1rem'}`,
                    paddingRight: `${isMobile ? '0.3rem' : '1rem'}`,
                    WebkitTextStroke: '0.3px',
                  }}
                >
                  You are currently not eligible for the XELSTRYM Copay Savings Program.
                </span>
                <span style={{ paddingTop: '1rem' }}>
                  We will reach out to you with future offers and updates.
                </span>
              </Stack>
              <Stack
                style={{
                  paddingTop: `${isMobile ? '1rem' : '2rem'}`,
                  width: `${isMobile ? '100%' : '70%'}`,
                  fontSize: `${isMobile ? '15px' : '20px'}`,
                }}
              >
                <span>
                  Please see program{' '}
                  {isMobile ? (
                    <InlineModalMobile content={termsConditionsJSX()}>
                      <>Terms & Conditions</>
                    </InlineModalMobile>
                  ) : (
                    <InlineModal content={termsConditionsJSX()}>
                      <>Terms & Conditions</>
                    </InlineModal>
                  )}{' '}
                  and visit{' '}
                  <Link
                    style={{ color: XelstrymTheme.palette.green, textDecoration: 'underline' }}
                    target="_blank"
                    href="https://www.xelstrym.com/"
                    rel="noreferrer"
                  >
                    xelstrym.com
                  </Link>{' '}
                  for more information.
                </span>
              </Stack>
            </Stack>
          </Stack>
        </div>
        <Stack horizontalAlign="center" style={{ padding: `${isMobile ? '1rem' : '2rem'}` }}>
          <PrimaryButton
            className={mergeStyles({
              height: '3rem',
              minWidth: '148px',
              borderRadius: '5px',
              boxShadow: theme.effects.elevation8,
              border: `1px solid ${theme.palette.green}`,
              color: theme.palette.white,
              background: theme.palette.green,
              fontSize: '16px',
              font: 'Centra No2',
              fontWeight: '700',
              ':hover': {
                background: theme.palette.green,
                border: `1px solid ${theme.palette.green}`,
                color: theme.palette.white,
              },
              ':active': {
                background: theme.palette.green,
                border: `1px solid ${theme.palette.green}`,
                color: theme.palette.white,
              },
            })}
            onClick={handleClose}
          >
            Close
          </PrimaryButton>
        </Stack>
      </Modal>
    </>
  );
};
