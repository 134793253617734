/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { Stack, useTheme, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { ButtonGroup } from './ButtonGroup';
import { ContextualForm } from './ContextualForm';
import { Input } from './types';
import { createEnrollment, createIneligibleEnrollment } from '../xelstrym/Xelstrym.services';
import { SavingsCard } from './SavingsCard';
import { EnrollWithoutCard } from './EnrollWithoutCard';
import { HomePage } from './HomePage';
import { ErrorModal } from './ErrorModal';
import { UAParser } from 'ua-parser-js';
import NovenCANLogo from '../assets/novencan-logo.png';

export interface NearbyPharmacy {
  name: string;
  address: string;
  city: string;
  state: string;
  phone: string;
  coordinates: {
    x: number;
    y: number;
  };
  distance: number;
  NABP: number;
  NPI: number;
}

export const AppController = (props: { isMobile: boolean }) => {
  const { isMobile } = props;
  const theme = useTheme();

  const [above18, setAbove18] = useState('');
  const [loading, isLoading] = useState(false);
  const [enrollmentStarted, setEnrollmentStarted] = useState(false);
  const [enrollment, setEnrollment] = React.useState<any>();
  const [nearbyPharmacy, setNearbyPharmacy] = React.useState<NearbyPharmacy[]>();
  const [commerciallyInsured, setCommerciallyInsured] = useState('');
  const [enrolledWithCard, setEnrolledWithCard] = useState(false);
  const [enrolledWithoutCard, setEnrolledWithoutCard] = useState(false);
  const [ios, setIOS] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const os = parser.getOS().name;

    setIOS(os === 'iOS' || os === 'Mac OS');
  }, [ios]);

  const startEnrollment = () => {
    setEnrollmentStarted(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleEnrollWithoutCardModal = () => {
    setEnrolledWithoutCard(false);
  };

  const handleSubmit = async (inputs: Input[]) => {
    try {
      isLoading(true);
      if (above18 === 'yes' && commerciallyInsured === 'yes') {
        const { enrollment, nearbyPharmacies } = await createEnrollment(inputs);
        console.log('Nearby Pharmacy Data', nearbyPharmacies);
        console.log('Enrollment Data', enrollment);
        setEnrollment(enrollment);
        setNearbyPharmacy(nearbyPharmacies);
        isLoading(false);
        setEnrolledWithCard(true);
      } else {
        const enrollWithoutCard = await createIneligibleEnrollment(inputs);
        console.log('Enroll Without Card', enrollWithoutCard);
        isLoading(false);
        setEnrolledWithoutCard(true);
      }
    } catch (err: any) {
      console.log('Error', err);
      isLoading(false);
    }
  };

  if (!enrollmentStarted) {
    return <HomePage startEnrollment={startEnrollment} isMobile={isMobile} />;
  } else {
    if (enrolledWithCard) {
      return (
        <Stack horizontalAlign="center" verticalAlign="center">
          <SavingsCard
            enrollmentData={enrollment}
            isMobile={isMobile}
            nearbyPharmacies={nearbyPharmacy}
          />
        </Stack>
      );
    } else {
      return isMobile ? (
        <>
          <Stack style={{ width: '100%', height: '3.5rem', background: '#509E2F' }}></Stack>
          <Stack horizontalAlign="center" verticalAlign="center" style={{ maxWidth: '100vw' }}>
            <Stack
              horizontalAlign="center"
              style={{
                width: '100%',
                height: 'fit-content',
                background:
                  'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
                color: '#333',
                marginTop: '1rem',
                fontSize: '24px',
                fontWeight: '400',
                paddingTop: '1rem',
              }}
            >
              <Stack horizontalAlign="center" style={{ width: '100%', paddingTop: '4rem' }}>
                <img src={NovenCANLogo} width="290" style={{ maxWidth: '100%'}}/>
              </Stack>
              <Stack horizontalAlign="center" style={{ width: '100%', paddingTop: '1.5rem' }}>
                <span
                  style={{
                    color: '#0047BB',
                    fontSize: '30px',
                    fontWeight: '700',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  Complete the 2 steps
                  <br /> below to enroll today
                </span>
              </Stack>
              <Stack
                horizontal
                horizontalAlign="center"
                verticalAlign="center"
                style={{ maxWidth: '100%', paddingTop: '1rem' }}
              >
                <hr
                  style={{
                    border: '1px solid #0047BB',
                    width: '50vw',
                    padding: 0,
                    margin: 0,
                  }}
                ></hr>
                <Stack
                  verticalAlign="center"
                  horizontalAlign="center"
                  style={{
                    border: '1px solid #0047BB',
                    borderRadius: '20px',
                    padding: '0.3rem',
                    maxHeight: '3rem',
                    background: '#ffffff',
                    fontSize: '20px',
                    fontWeight: '900',
                    color: '#509E2F',
                    width: '50vw',
                    textAlign: 'center',
                    minWidth: 'fit-content',
                  }}
                >
                  <span
                    style={{ fontFamily: 'Centra No2 Bold' }}
                  >
                    Step 1 of 2
                  </span>
                </Stack>
                <hr
                  style={{
                    border: '1px solid #0047BB',
                    width: '50vw',
                    padding: 0,
                    margin: 0,
                  }}
                ></hr>
              </Stack>
              <ButtonGroup onChange={setAbove18} isMobile={isMobile}>
                Do you verify that you are age 18 or older? To opt in for this program you, as
                patient or caregiver, must be over 18; however, the savings offer may be used for
                all eligible patients aged 6 years and older.
              </ButtonGroup>
              {above18 === 'no' && (
                <ErrorModal>
                  <span>
                    We’re sorry, but based on your answer that you are not a patient or caregiver
                    aged 18 years or older, you are not eligible for the XELSTRYM Copay Savings
                    Program at this time.
                  </span>
                </ErrorModal>
              )}
              <ButtonGroup onChange={setCommerciallyInsured} isMobile={isMobile}>
                Do you confirm that the patient is NOT enrolled in a federal or state
                government-funded insurance plan including Medicare, Medicaid, DoD, TRICARE, VA, or
                any other government-funded plan?
              </ButtonGroup>
              {commerciallyInsured === 'no' && (
                <ErrorModal>
                  <span>
                    We’re sorry, but based on your response that you (or the patient for whom you
                    are a caregiver) are enrolled in a government-funded insurance plan, you are not
                    eligible for the XELSTRYM Copay Savings Program at this time.
                    <br />
                    <b>
                      However, you may still fill out the form below to receive future offers and
                      updates.
                    </b>
                  </span>
                </ErrorModal>
              )}
              {above18 && commerciallyInsured && above18 !== 'no' && (
                <>
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{ maxWidth: '100%', paddingTop: '1rem' }}
                  >
                    <hr
                      style={{
                        border: '1px solid #0047BB',
                        width: '50vw',
                        padding: 0,
                        margin: 0,
                      }}
                    ></hr>
                    <Stack
                      verticalAlign="center"
                      horizontalAlign="center"
                      style={{
                        border: '1px solid #0047BB',
                        borderRadius: '20px',
                        padding: '0.3rem',
                        maxHeight: '3rem',
                        background: '#ffffff',
                        fontSize: '20px',
                        fontWeight: '700',
                        color: '#509E2F',
                        width: '50vw',
                        textAlign: 'center',
                        minWidth: 'fit-content',
                      }}
                    >
                      <span
                        style={{
                          fontFamily: 'Centra No2 Bold',
                        }}
                      >
                        Step 2 of 2
                      </span>
                    </Stack>
                    <hr
                      style={{
                        border: '1px solid #0047BB',
                        width: '50vw',
                        padding: 0,
                        margin: 0,
                      }}
                    ></hr>
                  </Stack>
                  <Stack
                    horizontalAlign="center"
                    style={{
                      paddingTop: `${isMobile ? '3rem' : '2rem'}`,
                      width: '70%',
                      height: 'fit-content',
                    }}
                  >
                    <ContextualForm
                      inputs={[
                        {
                          id: 1,
                          type: 'text',
                          key: 'FIRST_NAME',
                          value: '',
                          name: 'First Name',
                          validator: 'name',
                        },
                        {
                          id: 2,
                          type: 'text',
                          key: 'LAST_NAME',
                          value: '',
                          name: 'Last Name',
                          validator: 'name',
                        },
                        {
                          id: 3,
                          type: 'email',
                          key: 'EMAIL',
                          value: '',
                          name: 'Email',
                          validator: 'email',
                        },
                        {
                          id: 4,
                          type: 'tel',
                          key: 'PHONE',
                          value: '',
                          name: 'Mobile Number',
                          validator: 'phone',
                        },
                        {
                          id: 5,
                          type: 'text',
                          key: 'ZIPCODE',
                          value: '',
                          name: 'ZIP Code',
                          validator: 'zipcode',
                        },
                      ]}
                      onSubmit={handleSubmit}
                      isMobile={isMobile}
                      above18={above18}
                      submitLoading={loading}
                    ></ContextualForm>
                  </Stack>
                  {enrolledWithoutCard && (
                    <>
                      <EnrollWithoutCard
                        isMobile={isMobile}
                        isOpen={enrolledWithoutCard}
                        handleClose={handleEnrollWithoutCardModal}
                      >
                        Thank you!
                      </EnrollWithoutCard>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack horizontalAlign="center" verticalAlign="center" style={{ maxWidth: '100vw' }}>
          <Stack
            horizontalAlign="center"
            style={{
              width: '100%',
              height: 'fit-content',
              background: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
              color: '#333',
              padding: '1rem',
              marginTop: '1rem',
              fontSize: '24px',
              fontWeight: '400',
              paddingTop: '1rem',
            }}
          >
            <Stack horizontalAlign="center" style={{ width: '90%', paddingTop: '2rem' }}>
                <img src={NovenCANLogo} width="290"/>
              </Stack>
            <Stack horizontalAlign="center" style={{ width: '90%' }}>
              <h1
                style={{
                  color: '#0047BB',
                  fontSize: '44px',
                  fontWeight: '700',
                  fontFamily: 'Centra No2 Bold',
                }}
              >
                Complete the 2 steps below to enroll today
              </h1>
            </Stack>
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="center"
              style={{ maxWidth: '100%', paddingTop: '3rem' }}  
            >
              <hr
                style={{
                  border: '1px solid #0047BB',
                  width: '25vw',
                  padding: 0,
                  margin: 0,
                }}
              ></hr>
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                style={{
                  border: '1px solid #0047BB',
                  borderRadius: '20px',
                  padding: '1rem',
                  maxHeight: '3rem',
                  background: '#ffffff',
                  fontSize: '24px',
                  fontWeight: '900',
                  color: '#509E2F',
                  width: '10vw',
                  textAlign: 'center',
                  minWidth: 'fit-content',
                }}
              >
                <span style={{ marginTop: 0, fontFamily: 'Centra No2 Bold' }}>Step 1 of 2</span>
              </Stack>
              <hr
                style={{
                  border: '1px solid #0047BB',
                  width: '25vw',
                  padding: 0,
                  margin: 0,
                }}
              ></hr>
            </Stack>
            <ButtonGroup onChange={setAbove18} isMobile={isMobile}>
              Do you verify that you are age 18 or older? To opt in for this program you, as patient
              or caregiver, must be over 18; however, the savings offer may be used for all eligible
              patients aged 6 years and older.
            </ButtonGroup>
            {above18 === 'no' && (
              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  background: '#0047BB',
                  color: '#FFFFFF',
                  borderRadius: '11px',
                  width: '60%',
                  height: 'fit-content',
                  padding: '1.3rem',
                  fontSize: '18px',
                  fontWeight: '400',
                  marginTop: '8px',
                  textAlign: 'center',
                }}
              >
                <span>
                  We’re sorry, but based on your answer that you are not a patient or caregiver aged
                  18 years or older, you are not eligible for the XELSTRYM Copay Savings Program at
                  this time.
                </span>
              </Stack>
            )}
            <ButtonGroup onChange={setCommerciallyInsured} isMobile={isMobile}>
              Do you confirm that the patient is NOT enrolled in a federal or state
              government-funded insurance plan including Medicare, Medicaid, DoD, TRICARE, VA, or
              any other government-funded plan?
            </ButtonGroup>
            {commerciallyInsured === 'no' && (
              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  background: '#0047BB',
                  color: '#FFFFFF',
                  borderRadius: '11px',
                  width: '60%',
                  height: 'fit-content',
                  padding: '1.3rem',
                  fontSize: '18px',
                  fontWeight: '400',
                  marginTop: '8px',
                  textAlign: 'center',
                  marginBottom: '1rem',
                }}
              >
                <span>
                  We’re sorry, but based on your response that you (or the patient for whom you are
                  a caregiver) are enrolled in a government-funded insurance plan, you are not
                  eligible for the XELSTRYM Copay Savings Program at this time.
                  <br />
                  <b>
                    However, you may still fill out the form below to receive future offers and
                    updates.
                  </b>
                </span>
              </Stack>
            )}
            {above18 !== 'no' && (
              <>
                <Stack
                  horizontal
                  horizontalAlign="center"
                  verticalAlign="center"
                  style={{ paddingTop: '1rem' }}
                >
                  <hr
                    style={{
                      border: '1px solid #0047BB',
                      width: '25vw',
                      padding: 0,
                      margin: 0,
                    }}
                  ></hr>
                  <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                      border: '1px solid #0047BB',
                      borderRadius: '20px',
                      padding: '1rem',
                      maxHeight: '3rem',
                      background: '#ffffff',
                      fontSize: '24px',
                      fontWeight: '700',
                      color: '#509E2F',
                      width: '10vw',
                      minWidth: 'fit-content',
                      marginTop: '1rem',
                    }}
                  >
                    <span
                      style={{
                        marginTop: 0,
                        fontFamily: 'Centra No2 Bold',
                      }}
                    >
                      Step 2 of 2
                    </span>
                  </Stack>
                  <hr
                    style={{
                      border: '1px solid #0047BB',
                      width: '25vw',
                      padding: 0,
                      margin: 0,
                    }}
                  ></hr>
                </Stack>
                <Stack
                  horizontalAlign="center"
                  style={{
                    paddingTop: `${isMobile ? '3rem' : '2rem'}`,
                    width: '40%',
                    height: 'fit-content',
                  }}
                >
                  <ContextualForm
                    inputs={[
                      {
                        id: 1,
                        type: 'text',
                        key: 'FIRST_NAME',
                        value: '',
                        name: 'First Name',
                        validator: 'name',
                      },
                      {
                        id: 2,
                        type: 'text',
                        key: 'LAST_NAME',
                        value: '',
                        name: 'Last Name',
                        validator: 'name',
                      },
                      {
                        id: 3,
                        type: 'email',
                        key: 'EMAIL',
                        value: '',
                        name: 'Email',
                        validator: 'email',
                      },
                      {
                        id: 4,
                        type: 'tel',
                        key: 'PHONE',
                        value: '',
                        name: 'Mobile Number',
                        validator: 'phone',
                      },
                      {
                        id: 5,
                        type: 'text',
                        key: 'ZIPCODE',
                        value: '',
                        name: 'ZIP Code',
                        validator: 'zipcode',
                      },
                    ]}
                    onSubmit={handleSubmit}
                    isMobile={isMobile}
                    above18={above18}
                    submitLoading={loading}
                  ></ContextualForm>
                </Stack>
                {enrolledWithoutCard && (
                  <>
                    <EnrollWithoutCard
                      isMobile={isMobile}
                      isOpen={enrolledWithoutCard}
                      handleClose={handleEnrollWithoutCardModal}
                    >
                      Thank you!
                    </EnrollWithoutCard>
                  </>
                )}
              </>
            )}
            {above18 === 'no' && (
              <>
                <Stack
                  horizontalAlign="center"
                  style={{
                    paddingTop: `${isMobile ? '3rem' : '2rem'}`,
                    width: '60%',
                    height: 'fit-content',
                  }}
                >
                  <PrimaryButton
                    style={{
                      height: '3rem',
                      minWidth: '145px',
                      borderRadius: '5px',
                      boxShadow: theme.effects.elevation8,
                      border: '1px solid #999',
                      backgroundColor: '#999',
                      color: '#fff',
                      fontSize: '18px',
                      fontWeight: '700',
                      fontFamily: 'Centra No2 Bold',
                    }}
                    disabled={true}
                  >
                    Submit
                  </PrimaryButton>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      );
    }
  }
};
