import { Stack } from '@fluentui/react';
import React from 'react';
import NovenLogo from '../assets/noven-footer-logo.png';

export const Footer = (props: { isMobile: boolean }) => {
  const { isMobile } = props;

  const getFooterCode = () => {
    const url = window.location.href;
    if (url.includes('patient-letter')) {
      return 'XEL-0023-16 07/2024';
    } else {
      return 'XEL-3518-16 07/2024';
    }
  };

  return (
    <>
      {isMobile ? (
        <Stack style={{ paddingTop: '0rem' }}>
          <Stack>
            <span
              style={{
                color: '#333',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '700',
                padding: '1rem',
                fontFamily: 'Centra No2 Bold',
              }}
            >
              Please{' '}
              <a
                href="https://www.xelstrym.com/pdfs/prescribing-information.pdf"
                style={{ color: 'inherit' }}
                target="_blank"
                rel="noreferrer"
              >
                <u>click here</u>
              </a>{' '}
              for the Medication Guide and Full Prescribing Information, including the BOXED
              WARNING.
            </span>
          </Stack>
          <Stack
            style={{
              background: '#0047BB',
              paddingBottom: 0,
              marginBottom: 0,
              marginTop: '1rem',
            }}
          >
            <Stack
              horizontal
              horizontalAlign="start"
              verticalAlign="start"
              style={{
                background: '#0047BB',
                paddingTop: '1rem',
                paddingLeft: '0.5rem',
                width: 'inherit',
                margin: 0,
                color: '#ffffff',
                fontSize: '10px',
                fontWeight: '700',
              }}
            >
              <a
                href="https://www.noven.com/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '0.3rem' }}
                target="_blank"
                rel="noreferrer"
              >
                ABOUT NOVEN
              </a>
              <a
                href="https://www.noven.com/terms-of-use/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '0.3rem' }}
                target="_blank"
                rel="noreferrer"
              >
                TERMS OF USE
              </a>
              <a
                href="https://www.noven.com/privacy-policy/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '0.3rem' }}
                target="_blank"
                rel="noreferrer"
              >
                PRIVACY POLICY
              </a>
              <a
                href="https://www.noven.com/contact-us/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '0.3rem' }}
                target="_blank"
                rel="noreferrer"
              >
                CONTACT US
              </a>
            </Stack>
            <span
              style={{
                color: 'inherit',
                fontSize: '12px',
                paddingLeft: '.85rem',
                paddingTop: '.5rem',
              }}
              onClick={() => {
                return;
              }}
            >
              For information, call{' '}
              <a href="tel:+1-800-455-8070"
                style={{ textDecoration: 'underline', color: 'inherit', cursor: 'pointer'}}
                title="CLICK TO DIAL - Mobile Only"
              >
                800-455-8070
              </a>
              {'.'}
            </span>
            <Stack style={{ paddingLeft: '1rem', paddingTop: '1.5rem', paddingBottom: '1rem' }}>
              <img
                src={NovenLogo}
                style={{ width: '200px', height: '40px', paddingBottom: '.5rem' }}
              />
              <span
                style={{
                  color: '#ffffff',
                  fontSize: '12px',
                  fontWeight: '400',
                  textAlign: 'start',
                }}
              >
                XELSTRYM<sup style={{ verticalAlign: 'top', fontSize: '7px' }}>®</sup> is a
                trademark of Noven Therapeutics, LLC. <br />
                Noven C.A.N. and its related logo are trademarks of Noven Therapeutics, LLC. <br />{' '}
                ©2024 Noven Therapeutics, LLC. All rights reserved. For US audiences only. <br />{' '}
                {getFooterCode()}
              </span>
            </Stack>

            <hr
              style={{
                border: '1rem solid #509E2F',
                width: 'inherit',
                padding: 0,
                margin: 0,
              }}
            ></hr>
          </Stack>
        </Stack>
      ) : (
        <Stack style={{ paddingTop: '1rem' }}>
          <Stack verticalAlign="center" style={{ paddingBottom: '1rem' }}>
            <span
              style={{
                color: '#333',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '700',
                fontFamily: 'Centra No2 Bold',
              }}
            >
              Please{' '}
              <a
                href="https://www.xelstrym.com/pdfs/prescribing-information.pdf"
                style={{ color: 'inherit' }}
                target="_blank"
                rel="noreferrer"
              >
                <u>click here</u>
              </a>{' '}
              for the Medication Guide and Full Prescribing Information, including the BOXED
              WARNING.
            </span>
          </Stack>
          <Stack
            style={{
              maxHeight: '20%',
              background: '#0047BB',
              paddingBottom: 0,
              marginBottom: 0,
              marginTop: '1rem',
            }}
          >
            <Stack
              horizontal
              horizontalAlign="start"
              verticalAlign="center"
              style={{
                background: '#0047BB',
                paddingLeft: '5rem',
                paddingTop: '1rem',
                width: 'inherit',
                margin: 0,
                color: '#ffffff',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              <a
                href="https://www.noven.com/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                ABOUT NOVEN
              </a>
              <a
                href="https://www.noven.com/terms-of-use/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                TERMS OF USE
              </a>
              <a
                href="https://www.noven.com/privacy-policy/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                PRIVACY POLICY
              </a>
              <a
                href="https://www.noven.com/contact-us/?__hstc=66069809.6b5d44d5e6e2e403a6c481a7907509ac.1670937996486.1671468591594.1671535581460.8&__hssc=66069809.2.1671535581460&__hsfp=1383244671"
                style={{ color: 'inherit', textDecoration: 'none', padding: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                CONTACT US
              </a>
              <span style={{ color: 'inherit', padding: '2rem' }}>
                For information, call{' '}
                <a
                  href="tel:+1-800-455-8070"
                  style={{ textDecoration: 'underline', color: 'inherit', cursor: 'pointer'}}
                  title="CLICK TO DIAL - Mobile Only"  
                >
                  800-455-8070
                </a>
                {'.'}
              </span>
            </Stack>
            <Stack style={{ paddingLeft: '7rem', paddingTop: '4rem', paddingBottom: '3rem' }}>
              <img src={NovenLogo} style={{ width: '200px', height: '40px' }} />
              <span
                style={{
                  color: '#ffffff',
                  fontSize: '13px',
                  fontWeight: '400',
                  textAlign: 'start',
                  paddingTop: '10px',
                }}
              >
                XELSTRYM<sup style={{ verticalAlign: 'top', fontSize: '7px' }}>®</sup> is a
                trademark of Noven Therapeutics, LLC. <br />
                Noven C.A.N. and its related logo are trademarks of Noven Therapeutics, LLC. <br />{' '}
                ©2024 Noven Therapeutics, LLC. All rights reserved. For US audiences only. <br />{' '}
                {getFooterCode()}
              </span>
            </Stack>

            <hr
              style={{
                border: '1rem solid #509E2F',
                width: 'inherit',
                padding: 0,
                margin: 0,
              }}
            ></hr>
          </Stack>
        </Stack>
      )}
    </>
  );
};
