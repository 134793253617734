import {
  Stack,
  useTheme,
  Modal,
  PrimaryButton,
  ResponsiveMode,
  mergeStyles,
} from '@fluentui/react';
import React, { useEffect } from 'react';

export const ErrorModal = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props;
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const width = Math.min(window.innerWidth, window.outerWidth) * 0.9;

  useEffect(() => {
    // Diasble body scroll when Modal open
    if (isModalOpen) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Enable body scroll when Modal unmounts
      document.documentElement.style.overflow = 'unset';
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        responsiveMode={ResponsiveMode.small}
        allowTouchBodyScroll={true}
        styles={{
          main: {
            borderRadius: theme.effects.roundedCorner6,
            height: 'auto',
            background: '#0047BB',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: width,
            padding: '1rem',
          },
        }}
      >
        <div
          className={mergeStyles({
            width: 'inherit',
            borderRadius: `${theme.effects.roundedCorner6} ${theme.effects.roundedCorner6} 0 0`,
            background: '#0047BB',
            textAlign: 'center',
          })}
        >
          <Stack
            style={{
              color: '#fff',
              padding: '0.4rem',
              maxHeight: 'fit-content',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              fontSize: '16px',
              fontWeight: '400',
              justifyContent: 'center',
            }}
          >
            {children}
          </Stack>
        </div>
        <Stack horizontalAlign="center" style={{ padding: '1rem' }}>
          <PrimaryButton
            className={mergeStyles({
              height: '3rem',
              minWidth: '148px',
              borderRadius: '5px',
              border: `2px solid ${theme.palette.white}`,
              color: theme.palette.white,
              background: theme.palette.green,
              fontSize: '16px',
              font: 'Centra No2',
              fontWeight: '700',
              ':hover': {
                background: theme.palette.green,
                border: `1px solid ${theme.palette.green}`,
                color: theme.palette.white,
              },
              ':active': {
                background: theme.palette.green,
                border: `1px solid ${theme.palette.green}`,
                color: theme.palette.white,
              },
            })}
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </PrimaryButton>
        </Stack>
      </Modal>
    </>
  );
};
