import { Theme } from '@fluentui/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const contextualFormStyles = (theme: Theme) => ({
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    cursor: 'pointer',
    li: {
      padding: '8px 12px',
      border: '1px solid #707070',
      color: '#333',
      borderRadius: '5px',
      // boxShadow: theme.effects.elevation8,
      backgroundColor: theme.palette.neutralPrimary,
      marginBottom: '1.2rem',
    },
  },
  inputField: {
    border: 'none',
    color: theme.palette.black,
    ':focus': {
      outline: 'none',
      color: theme.palette.black,
    },
    height: 'auto',
    fontSize: theme.fonts.medium.fontSize,
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#D6D6D6',
    },
  },
  button: {
    minWidth: '10vw',
    marginTop: '1.5rem',
    border: 'none',
    padding: '16px 32px',
    fontSize: '20px',
    borderRadius: '4px',
    height: '58px',
    color: theme.palette.white,
    background: theme.palette.green,
    ':disabled': {
      background: '#BABABA',
      color: theme.palette.white,
      pointerEvents: 'none',
    },
    ':active': {
      background: theme.palette.green,
      border: 'none',
    },
    ':hover': {
      background: theme.palette.green,
      border: 'none',
    },
  },
});
