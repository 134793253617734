import { Stack, ThemeProvider } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import { XelstrymTheme } from './Xelstrym.theme';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

export const PatientLetter = () => {
  const [isMobile, setIsMobile] = useState(false);

  const xelstrymTheme = {
    ...XelstrymTheme,
    fonts: {
      ...XelstrymTheme.fonts,
    },
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const device = parser.getDevice().type;

    const isMobileDevice = device === 'mobile';
    const isSmallScreen = window.innerWidth <= 1000;

    setIsMobile(isMobileDevice || isSmallScreen);
  }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider
      theme={xelstrymTheme}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #E2F0FA 0%, #FFF 100%)',
      }}
    >
      <Header isMobile={isMobile} />
      {isMobile && <Stack style={{ width: '100%', height: '3.5rem', background: '#509E2F' }}></Stack>}

      <Stack horizontalAlign="center" verticalAlign="center">
        <Stack
          horizontalAlign="center"
          verticalAlign="space-evenly"
          style={{
            textAlign: 'center',
            width: `${isMobile ? '90%' : '60%'}`,
            height: 'fit-content',
            background: '#ffffff',
            color: '#54555A',
            // padding: `${isMobile ? '0.8rem' : '1.3rem'}`,
            marginBottom: '4rem',
            marginTop: `${isMobile ? '5rem' : '5rem'}`,
            fontSize: '16px',
            fontWeight: '400',
            // paddingBottom: '1rem',
            paddingTop: `${isMobile ? '2rem' : '2rem'}`,
            border: '1px solid #509E2F',
            borderRadius: '15px',
          }}
        >
          <Stack>
            <span
              style={{
                fontWeight: '700',
                fontSize: `${isMobile ? '18px' : '20px'}`,
                fontFamily: 'Centra No2 Bold',
                padding: '1rem',
                width: '90%',
                color: '#0047BB',
                textAlign: 'center',
              }}
            >
              Important Update to the XELSTRYM Copay Savings Program
            </span>

            <Stack
              style={{
                paddingTop: '1rem',
                paddingLeft: `${isMobile ? '2rem' : '5rem'}`,
                paddingRight: `${isMobile ? '2rem' : '5rem'}`,
                lineHeight: '19px',
              }}
            >
              <span
                style={{
                  fontSize: `${isMobile ? '14px' : '14px'}`,
                  fontWeight: '400',
                  fontFamily: 'Centra No2',
                  textAlign: 'justify',
                }}
              >
                <p>Dear XELSTRYM Patient,</p>

                <p>We hope this message finds you well. We are writing to inform you of some upcoming changes to the XELSTRYM Copay Savings Program that will take effect on July 15, 2024. Our commitment to providing you with ongoing support remains strong. All commercially insured patients will continue to receive out-of-pocket support for XELSTRYM.</p>

                <strong>What’s Changing?</strong>

                <p>If you continue to utilize a Noven C.A.N.™ Partner Pharmacy, you will pay as little as $25 for each XELSTRYM prescription. If you utilize a Non-Partner Pharmacy, you will pay as little as $50 for each XELSTRYM prescription. Both of these offers are limited to one use per 30-day supply and there is no change to the cash-paying offer (receive up to $200 off each prescription at a Partner Pharmacy or up to $100 off each prescription at a Non-Partner Pharmacy.)</p>

                <strong>Why Are These Changes Happening?</strong>

                <p>The XELSTRYM copay offer has evolved to ensure continued commitment to copay assistance for commercially insured patients throughout their treatment journey.</p>

                <strong>What Does This Mean for You?</strong>

                <p>You will not need to re-enroll; in fact, there is nothing additional you need to do in order to continue participating in the XELSTRYM Copay Savings Program. The changes made to rules have been reflected behind the scenes.</p>

                <strong>Where Can You Find More Information?</strong>

                <ul>
                  <li>Please visit <a href="https://xelstrym.com" style={{textDecoration: 'underline', color: 'inherit'}}>xelstrym.com</a> for eligibility information and updated terms and conditions</li>
                  <li>To find a Noven C.A.N. Partner Pharmacy near you, visit <a href="https://xelstrym.com/pharmacy-finder" style={{textDecoration: 'underline', color: 'inherit', wordBreak: 'break-all'}}>xelstrym.com/pharmacy-finder</a>! Then, work with your healthcare provider to have the prescription sent to that Partner Pharmacy</li>
                  <li>For any questions or concerns, the Noven C.A.N. Patient Access Team is here to assist you. You can reach us at 833-483-2178</li>
                </ul>

                <strong>Next Steps:</strong>

                <p>Again, there is no action you need to take in order to continue participating in the XELSTRYM Copay Savings Program. We understand that changes can sometimes be confusing, and we want to assure you that we are committed to continuing to support you every step of the way.</p>
                <p style={{textAlign: 'center', color: '#0047BB', fontSize: '15px'}}><b>Thank you for your continued participation in the XELSTRYM Copay Savings Program!</b></p>
                <br />
              </span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Footer isMobile={isMobile} />
    </ThemeProvider>
  );
};
