/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeStyles, PrimaryButton, Spinner, Stack, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import AppleWalletImg from '../assets/Add-to-apple-wallet.png';
import GoogleWalletImg from '../assets/Add-to-google-wallet.png';
import DirectionsLogo from '../assets/directions-logo.png';
import DownloadCardLogo from '../assets/download-card-logo.png';
import AddToContactsLogo from '../assets/add-to-contacts-logo.png';
import { NearbyPharmacy } from './AppController';
import GoogleMapComponent, { getDirections } from './GoogleMaps';
import { InlineModalMobile } from './InlineModalMobile';
import { InlineModal } from './InlineModal';
import { termsConditionsJSX } from '../xelstrym/Xelstrym.web';

export const SavingsCard = (props: {
  enrollmentData: any;
  isMobile: boolean;
  nearbyPharmacies: NearbyPharmacy[] | undefined;
}) => {
  const { enrollmentData, isMobile, nearbyPharmacies } = props;
  const theme = useTheme();
  const [enrollment, setEnrollment] = React.useState<any>();
  const [nearbyPharmacy, setNearbyPharmacy] = React.useState<any>();
  const [ios, setIOS] = useState(false);
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const os = parser.getOS().name;

    setIOS(os === 'iOS');
  }, [ios]);

  useEffect(() => {
    setEnrollment(enrollmentData);
    setNearbyPharmacy(nearbyPharmacies);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const qrCode = (enrollment: any) => {
    return enrollment?.ezWalletResponse?.qrImageUrl?.slice(0, -4) + '.png' + '?color=54555A';
  };

  const downloadPDF = (enrollment: any) => {
    isLoading(true);
    fetch(enrollment?.ezWalletResponse?.qrPdfUrl)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Xelstrym-savings-Offer-${enrollment?.memberId}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        isLoading(false);
      })
      .catch((error) => {
        console.log('File download error ', error);
        isLoading(false);
      });
  };

  const generateVCF = (name: string, phoneNumber: string) => {
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
FN:${name}
TEL:${phoneNumber}
END:VCARD`;

    return vcfContent;
  };

  const downloadContact = (pharmacy: NearbyPharmacy) => {
    const vcfContent = generateVCF(pharmacy.name, pharmacy.phone);
    const blob = new Blob([vcfContent], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${pharmacy.name}.vcf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {isMobile ? (
        <>
          <Stack style={{ width: '100%', height: '3.5rem', background: '#509E2F' }}></Stack>
          {nearbyPharmacy && nearbyPharmacy.length ? (
            <Stack
              horizontalAlign="center"
              style={{
                textAlign: 'center',
                maxWidth: '100%',
                height: 'fit-content',
                background:
                  'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
                color: '#333',
                padding: '1rem',
                marginBottom: '1rem',
                marginTop: '3rem',
                fontFamily: 'Centra No2',
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              <Stack
                horizontalAlign="center"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
              >
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '30px',
                    color: '#0047BB',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ fontFamily: 'Centra No2 Bold' }}>You&apos;re enrolled!</span>
                </span>
                <span style={{ width: '100%', padding: '0.5rem' }}>
                  Based on your ZIP code, we have located these Noven C.A.N.™ Partner Pharmacies
                  within 50 miles of your ZIP code. If there are multiple Partner Pharmacies in your
                  area, please work with your healthcare provider's office to determine which
                  Partner Pharmacy your prescription will be sent to.
                  <br />
                  <br />
                  There may be additional Partner Pharmacies available further from you that may
                  offer delivery and/or shipping of your prescription. Visit our{' '}
                  <a
                    href="https://www.xelstrym.com/pharmacy-finder"
                    style={{ color: '#509E2F', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner Pharmacy Finder
                  </a>{' '}
                  to learn more.
                </span>
              </Stack>
              <Stack
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '100%',
                  borderRadius: '15px',
                  background: '#ffffff',
                }}
              >
                <Stack
                  verticalAlign="center"
                  horizontalAlign="center"
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '21px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ padding: '0.8rem', width: '85%' }}>
                    Noven C.A.N. Partner Pharmacies in your area
                  </span>
                </Stack>
                <Stack style={{ width: '100%', padding: '1rem' }}>
                  <Stack
                    horizontalAlign="center"
                    style={{ width: '100%', border: '1px solid #ddd' }}
                  >
                    <GoogleMapComponent pharmacies={nearbyPharmacy} width="80vw" height="45vh" />
                  </Stack>
                  <Stack>
                    <Stack
                      horizontalAlign="center"
                      style={{
                        minWidth: '100%',
                        background: '#ffff',
                        border: '1px solid #ddd',
                        maxHeight: '400px',
                        overflow: 'auto',
                      }}
                    >
                      {nearbyPharmacy.map((pharmacy: any, index: number) => {
                        return (
                          <Stack
                            key={index}
                            style={{
                              background: '#ffff',
                              padding: '1rem',
                              minWidth: '100%',
                            }}
                          >
                            <Stack
                              horizontal
                              horizontalAlign="start"
                              style={{
                                paddingTop: '1rem',
                                paddingBottom: '1rem',
                                maxWidth: '100%',
                                marginLeft: '15%',
                              }}
                            >
                              <span
                                style={{
                                  color: 'white',
                                  background: '#0047BB',
                                  minWidth: '20px',
                                  minHeight: '20px',
                                  borderRadius: '50%',
                                  padding: '0.3rem 0.2rem 0.2rem 0.2rem',
                                  fontSize: '16px',
                                  maxHeight: '20px',
                                  maxWidth: '20px',
                                }}
                              >
                                {index + 1}
                              </span>
                              <Stack
                                style={{
                                  textAlign: 'left',
                                  fontSize: '14px',
                                  padding: 0,
                                  margin: '5px',
                                }}
                              >
                                <Stack
                                  horizontal
                                  horizontalAlign="start"
                                  verticalAlign="center"
                                  style={{ color: '#0047BB' }}
                                >
                                  <span style={{ fontFamily: 'Centra No2 Bold' }}>
                                    {pharmacy.name} ({pharmacy.distance} miles)
                                  </span>
                                </Stack>{' '}
                                {pharmacy.address}
                                <br />
                                {pharmacy.city}, {pharmacy.state},
                                <br />
                                NPI: {pharmacy.NPI}
                                <br />
                                NABP: {pharmacy.NABP}
                                <br />
                                <span style={{ color: '#509E2F' }}>
                                  Tel: <u>{pharmacy.phone}</u>
                                </span>
                                <br />
                                <Stack
                                  horizontal
                                  verticalAlign="center"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    getDirections({
                                      lat: pharmacy.coordinates.y,
                                      lng: pharmacy.coordinates.x,
                                    })
                                  }
                                >
                                  <img
                                    src={DirectionsLogo}
                                    style={{ width: '30px', height: '30px' }}
                                  />
                                  <span style={{ color: '#509E2F', paddingLeft: '5px' }}>
                                    <u>Directions</u>
                                  </span>
                                </Stack>
                              </Stack>
                            </Stack>
                            <Stack horizontalAlign="center" style={{ width: '100%' }}>
                              <PrimaryButton
                                className={mergeStyles({
                                  height: '3rem',
                                  width: '95%',
                                  maxWidth: '100%',
                                  borderRadius: '5px',
                                  boxShadow: theme.effects.elevation8,
                                  border: `1px solid ${theme.palette.green}`,
                                  color: theme.palette.white,
                                  background: theme.palette.green,
                                  fontSize: '16px',
                                  font: 'Centra No2',
                                  fontWeight: '700',
                                  fontFamily: 'Centra No2 Bold',
                                  ':hover': {
                                    background: theme.palette.green,
                                    border: `1px solid ${theme.palette.green}`,
                                    color: theme.palette.white,
                                  },
                                  ':active': {
                                    background: theme.palette.green,
                                    border: `1px solid ${theme.palette.green}`,
                                    color: theme.palette.white,
                                  },
                                })}
                                onClick={() => downloadContact(pharmacy)}
                              >
                                Add to contacts
                                <img
                                  src={AddToContactsLogo}
                                  style={{
                                    float: 'left',
                                    width: '26px',
                                    height: '26px',
                                    marginLeft: '1rem',
                                  }}
                                />
                              </PrimaryButton>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
                {/* <Stack>
                  <Stack style={{ padding: '1rem' }}>
                    <span>
                      With this card, you may{' '}
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        pay as little as $0 for up to 12 prescriptions
                      </span>{' '}
                      at your local partner pharmacy.
                      <br />
                      <br />
                      To take advantage of this savings offer, please save this card to your digital
                      wallet by using the button below, or by downloading and printing it. Your
                      partner pharmacy will ask you for the information on the card.
                    </span>
                  </Stack>

                  <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{
                      width: '100%',
                      padding: '1rem',
                    }}
                  >
                    <img
                      src={enrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{ height: '190px', width: '305px' }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ padding: '1rem' }}
                    >
                      <img
                        src={ios ? AppleWalletImg : GoogleWalletImg}
                        style={{
                          width: `${ios ? '210px' : '198px'}`,
                          minHeight: `${ios ? '60px' : '54px'}`,
                          padding: 0,
                          margin: 0,
                          border: 'none',
                        }}
                        onClick={() => window.open(enrollment?.ezWalletResponse?.walletPassUrl)}
                      />
                    </Stack>
                    <Stack>
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(enrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                    <span style={{ fontSize: '14px', paddingTop: '1rem' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        NOTE:
                        <br />
                      </span>{' '}
                      Your card information will also be sent via SMS.
                    </span>
                  </Stack>
                </Stack> */}
              </Stack>
              <Stack
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '100%',
                  borderRadius: '15px',
                  background: '#ffffff',
                  marginTop: '1rem',
                }}
              >
                <Stack
                  verticalAlign="center"
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '21px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                    {/* Non-partner pharmacy
                    <br /> savings offer */}
                    XELSTRYM Copay Savings Offer
                  </span>
                </Stack>
                <Stack>
                  <Stack style={{ padding: '1rem' }} horizontalAlign="center">
                    <span style={{ width: '90%' }}>
                      If you use this card at a{' '}
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        Noven C.A.N. Partner Pharmacy,
                      </span>{' '}
                      you will{' '}
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        pay as little as $25 for each XELSTRYM prescription.*
                      </span>
                      <br />
                      <br />
                      To take advantage of this savings offer, please save this card to your digital
                      wallet by clicking "Add to Wallet", or by downloading and printing it. Your
                      pharmacy will ask you for the information on the card.
                    </span>
                  </Stack>

                  <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{
                      width: '100%',
                      padding: '1rem',
                    }}
                  >
                    <img
                      src={enrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{ height: '190px', width: '305px' }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ padding: '1rem' }}
                    >
                      <img
                        src={ios ? AppleWalletImg : GoogleWalletImg}
                        style={{
                          width: `${ios ? '210px' : '198px'}`,
                          minHeight: `${ios ? '60px' : '54px'}`,
                          padding: 0,
                          margin: 0,
                          border: 'none',
                        }}
                        onClick={() => window.open(enrollment?.ezWalletResponse?.walletPassUrl)}
                      />
                    </Stack>
                    <Stack>
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(enrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                    <span style={{ fontSize: '14px', paddingTop: '1rem' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>NOTE:</span> As long as you
                      provided your mobile number during enrollment, your card information will also
                      be sent via SMS.
                      <br />
                      <br />
                      *Copay savings offer is available for commercially insured patients. At Noven
                      C.A.N. Partner Pharmacies, eligible patients pay as little as $25 for each
                      XELSTRYM prescription. At non-Partner Pharmacies, eligible patients pay as
                      little as $50 for each XELSTRYM prescription. Patient is responsible for any
                      remaining balance due after copay savings offer is applied. An offer is
                      available for cash-paying patients.{' '}
                      <InlineModalMobile content={termsConditionsJSX()}>
                        Click here
                      </InlineModalMobile>{' '}
                      for pharmacist instructions, eligibility instructions, and full terms and
                      conditions.
                    </span>
                  </Stack>
                </Stack>
              </Stack>

              <Stack horizontalAlign="center" style={{ paddingTop: '2rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    border: `1px solid ${theme.palette.green}`,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    fontSize: '16px',
                    font: 'Centra No2',
                    fontWeight: '700',
                    minWidth: 'fit-content',
                    fontFamily: 'Centra No2 Bold',
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => window.open('https://www.xelstrym.com/')}
                >
                  Visit xelstrym.com
                </PrimaryButton>
              </Stack>
            </Stack>
          ) : (
            <Stack
              horizontalAlign="center"
              style={{
                textAlign: 'center',
                maxWidth: '100%',
                height: 'fit-content',
                background:
                  'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
                color: '#333',
                padding: '1rem',
                marginBottom: '1rem',
                marginTop: '3rem',
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              <Stack
                horizontalAlign="center"
                style={{
                  paddingTop: '2rem',
                }}
              >
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '30px',
                    color: '#0047BB',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  No Noven C.A.N.™ Partner Pharmacies in your area—but you can still save!
                </span>
                <span style={{ width: '100%', padding: '0.5rem' }}>
                  At this time, there is not a Noven C.A.N. Partner Pharmacy within 50 miles of your
                  ZIP code. There may be additional Partner Pharmacies available further from you
                  that may offer delivery and/or shipping of your prescription. Visit our{' '}
                  <a
                    href="https://www.xelstrym.com/pharmacy-finder"
                    style={{ color: '#509E2F', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner Pharmacy Finder
                  </a>{' '}
                  to learn more.
                  <br />
                  <br />
                  In the meantime, you can still take advantage of our copay savings offer below at
                  any US retail pharmacy. If a Partner Pharmacy becomes available in your area in
                  the future, this card may be used at that pharmacy for potential additional
                  savings.
                </span>
              </Stack>

              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '100%',
                  borderRadius: '15px',
                  background: '#ffffff',
                  marginTop: '1rem',
                }}
              >
                <Stack
                  verticalAlign="center"
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '21px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ padding: '1rem' }}>
                    {/* Non-partner pharmacy <br />
                    savings offer */}
                    XELSTRYM Copay Savings Offer
                  </span>
                </Stack>

                <Stack style={{ padding: '1rem 0.3rem', width: '100%' }}>
                  <span style={{ width: '95%' }}>
                    At a{' '}
                    <span style={{ fontFamily: 'Centra No2 Bold' }}>
                      non-NOVEN C.A.N. Partner Pharmacy, insured patients will pay as little as $50
                      for each XELSTRYM prescription.*
                    </span>
                    <br />
                    <br />
                    Please save this card to your digital wallet by clicking "Add to Wallet", or by
                    downloading and printing it. Your pharmacy will ask you for the information on
                    the card.
                  </span>
                  <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{
                      width: '100%',
                      padding: '1rem',
                    }}
                  >
                    <img
                      src={enrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{ height: '190px', width: '305px' }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ padding: '1rem' }}
                    >
                      <img
                        src={ios ? AppleWalletImg : GoogleWalletImg}
                        style={{
                          width: `${ios ? '210px' : '198px'}`,
                          minHeight: `${ios ? '60px' : '54px'}`,
                          padding: 0,
                          margin: 0,
                          border: 'none',
                        }}
                        onClick={() => window.open(enrollment?.ezWalletResponse?.walletPassUrl)}
                      />
                    </Stack>
                    <Stack>
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(enrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                    <span style={{ fontSize: '14px', paddingTop: '1rem' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>NOTE:</span> As long as you
                      provided your mobile number during enrollment, your card information will also
                      be sent via SMS.
                      <br />
                      <br />
                      *Copay savings offer is available for commercially insured patients. At Noven
                      C.A.N. Partner Pharmacies, eligible patients pay as little as $25 for each
                      XELSTRYM prescription. At non-Partner Pharmacies, eligible patients pay as
                      little as $50 for each XELSTRYM prescription. Patient is responsible for any
                      remaining balance due after copay savings offer is applied. An offer is
                      available for cash-paying patients.{' '}
                      <InlineModalMobile content={termsConditionsJSX()}>
                        Click here
                      </InlineModalMobile>{' '}
                      for pharmacist instructions, eligibility instructions, and full terms and
                      conditions.
                    </span>
                  </Stack>
                </Stack>
              </Stack>
              <Stack horizontalAlign="center" style={{ paddingTop: '3rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    maxWidth: 'auto',
                    borderRadius: '5px',
                    minWidth: 'fit-content',
                    boxShadow: theme.effects.elevation8,
                    border: `1px solid ${theme.palette.green}`,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    fontSize: '16px',
                    font: 'Centra No2',
                    fontWeight: '700',
                    fontFamily: 'Centra No2 Bold',
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => window.open('https://www.xelstrym.com/')}
                >
                  Visit xelstrym.com
                </PrimaryButton>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <>
          {nearbyPharmacy && nearbyPharmacy.length ? (
            <Stack
              horizontalAlign="center"
              style={{
                textAlign: 'center',
                maxWidth: '70%',
                height: 'fit-content',
                background:
                  'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
                color: '#333',
                padding: '2rem',
                marginBottom: '2rem',
                marginTop: '1rem',
                fontSize: '18px',
                fontWeight: '400',
              }}
            >
              <Stack
                horizontalAlign="center"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
              >
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '48px',
                    color: '#0047BB',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  You&apos;re enrolled!
                </span>
                <span style={{ width: '80%', padding: '0.5rem' }}>
                  Based on your ZIP code, we have located these Noven C.A.N.™ Partner Pharmacies
                  within 50 miles of your ZIP code. If there are multiple Partner Pharmacies in your
                  area, please work with your healthcare provider's office to determine which
                  Partner Pharmacy your prescription will be sent to.
                  <br />
                  <br />
                  There may be additional Partner Pharmacies available further from you that may
                  offer delivery and/or shipping of your prescription. Visit our{' '}
                  <a
                    href="https://www.xelstrym.com/pharmacy-finder"
                    style={{ color: '#509E2F', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner Pharmacy Finder
                  </a>{' '}
                  to learn more.
                </span>
              </Stack>
              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '95%',
                  borderRadius: '15px',
                  background: '#ffffff',
                }}
              >
                <Stack
                  verticalAlign="center"
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '34px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ padding: '1rem' }}>
                    Noven C.A.N. Partner Pharmacies in your area
                  </span>
                </Stack>
                <Stack horizontal horizontalAlign="space-around" style={{ width: '95%' }}>
                  <Stack horizontal style={{ padding: '2rem' }}>
                    <Stack style={{ width: '30%', maxHeight: '60vh', overflowY: 'auto' }}>
                      {nearbyPharmacy.map((pharmacy: NearbyPharmacy, index: number) => {
                        return (
                          <Stack
                            key={index}
                            style={{
                              background: '#F1F8FD',
                              padding: '1.5rem',
                            }}
                          >
                            <Stack horizontal style={{ paddingTop: '1rem' }}>
                              <span
                                style={{
                                  color: 'white',
                                  background: '#0047BB',
                                  maxWidth: '25px',
                                  maxHeight: '25px',
                                  borderRadius: '50%',
                                  padding: '0.5rem 0.3rem 0.1rem 0.3rem',
                                  fontSize: '16px',
                                  minHeight: '25px',
                                  minWidth: '25px',
                                  textAlign: 'center',
                                }}
                              >
                                {index + 1}
                              </span>
                              <span
                                style={{
                                  textAlign: 'left',
                                  fontSize: '14px',
                                  padding: 0,
                                  margin: '5px',
                                }}
                              >
                                <Stack
                                  horizontal
                                  horizontalAlign="start"
                                  verticalAlign="center"
                                  style={{ color: '#0047BB' }}
                                >
                                  <span style={{ fontFamily: 'Centra No2 Bold' }}>
                                    {pharmacy.name} ({pharmacy.distance} miles)
                                  </span>
                                </Stack>
                                {pharmacy.address}
                                <br />
                                {pharmacy.city}, {pharmacy.state},
                                <br />
                                NPI: {pharmacy.NPI}
                                <br />
                                NABP: {pharmacy.NABP}
                                <br />
                                Tel:{' '}
                                <span style={{ color: '#509E2F' }}>
                                  <u>{pharmacy.phone}</u>
                                </span>{' '}
                                <br />
                                <br />
                                <Stack
                                  horizontal
                                  verticalAlign="center"
                                  onClick={() =>
                                    getDirections({
                                      lat: pharmacy.coordinates.y,
                                      lng: pharmacy.coordinates.x,
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    src={DirectionsLogo}
                                    style={{ width: '30px', height: '30px' }}
                                  />
                                  <span style={{ color: '#509E2F', paddingLeft: '5px' }}>
                                    <u>Directions</u>
                                  </span>
                                </Stack>
                              </span>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                    <Stack style={{ width: '70%', minHeight: '20%', maxHeight: '20%' }}>
                      <GoogleMapComponent pharmacies={nearbyPharmacy} width="38vw" height="60vh" />
                    </Stack>
                  </Stack>
                </Stack>
                {/* <Stack style={{ padding: '2rem' }}>
                  <span>
                    With this card, you may{' '}
                    <span style={{ fontFamily: 'Centra No2 Bold' }}>
                      pay as little as $0 for up to 12 prescriptions{' '}
                    </span>
                    at your local partner pharmacy.
                    <br />
                    <br />
                    To take advantage of this savings offer, please save this card to your digital
                    wallet by scanning the <br />
                    QR code below or by downloading and printing it. Your partner pharmacy will ask
                    you for the <br />
                    information on the card.
                  </span>
                  <Stack
                    horizontal
                    horizontalAlign="start"
                    verticalAlign="end"
                    style={{
                      minWidth: '85%',
                      paddingLeft: '2rem',
                      paddingBottom: '3rem',
                      paddingTop: '1rem',
                    }}
                  >
                    <img
                      src={networkEnrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{
                        height: '190px',
                        width: '305px',
                        paddingRight: '3rem',
                      }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ paddingRight: '1rem' }}
                    >
                      <img
                        src={qrCode(networkEnrollment)}
                        style={{ height: '95px', width: '95px', paddingBottom: '1rem' }}
                      />
                      <span style={{ color: '#333', fontSize: '16px' }}>
                        <span style={{ fontFamily: 'Centra No2 Bold' }}> Scan</span> the QR code and
                        add <br />
                        your card to your digital wallet
                      </span>
                    </Stack>
                    <span style={{ color: '#509E2F', paddingRight: '2rem', alignSelf: 'center' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>OR</span>
                    </span>
                    <Stack>
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(networkEnrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                marginTop: '4px',
                                marginLeft: '4px',
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                          paddingTop: '0.3rem',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                  </Stack>
                </Stack> */}
              </Stack>
              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '95%',
                  borderRadius: '15px',
                  background: '#ffffff',
                  marginTop: '2rem',
                }}
              >
                <Stack
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '34px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ paddingTop: '0.8rem', paddingBottom: '0.8rem' }}>
                    {/* Non-partner pharmacy savings offer */}
                    XELSTRYM Copay Savings Offer
                  </span>
                </Stack>

                <Stack style={{ padding: '1.5rem', width: '100%' }} horizontalAlign="center">
                  <Stack horizontalAlign="center" style={{ width: '100%' }}>
                    <span style={{ padding: '1rem', width: '80%' }}>
                      If you use this card at a{' '}
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        Noven C.A.N. Partner Pharmacy,
                      </span>{' '}
                      you will{' '}
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>
                        pay as little as $25 for each XELSTRYM prescription.*
                      </span>
                      <br />
                      <br />
                      To take advantage of this savings offer, please save this card to your digital
                      wallet by scanning the QR code below or by downloading and printing it. Your
                      pharmacy will ask you for the information on the card.
                    </span>
                  </Stack>
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="end"
                    style={{
                      minWidth: '85%',
                      paddingLeft: '2rem',
                      paddingBottom: '1.5rem',
                      paddingTop: '1rem',
                    }}
                  >
                    <img
                      src={enrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{ height: '190px', width: '305px', paddingRight: '3rem' }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ paddingRight: '1rem' }}
                    >
                      <img
                        src={qrCode(enrollment)}
                        style={{ height: '95px', width: '95px', paddingBottom: '1rem' }}
                      />
                      <span style={{ color: '#333', fontSize: '16px' }}>
                        <span style={{ fontFamily: 'Centra No2 Bold' }}> Scan</span> the QR code and
                        add <br />
                        your card to your digital wallet
                      </span>
                    </Stack>
                    <span style={{ color: '#509E2F', paddingRight: '2rem', alignSelf: 'center' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>OR</span>
                    </span>
                    <Stack>
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(enrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                marginTop: '4px',
                                marginLeft: '4px',
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                          paddingTop: '0.3rem',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                  </Stack>
                  <span style={{ textAlign: 'center', fontSize: '16px' }}>
                    <span style={{ fontFamily: 'Centra No2 Bold' }}>NOTE:</span> As long as you
                    provided your mobile number during enrollment, your card information will also
                    be sent via SMS.
                    <br />
                    <br />
                    *Copay savings offer is available for commercially insured patients. At Noven
                    C.A.N. Partner Pharmacies, eligible patients pay as little as $25 for each
                    XELSTRYM prescription. At non-Partner Pharmacies, eligible patients pay as
                    little as $50 for each XELSTRYM prescription. Patient is responsible for any
                    remaining balance due after copay savings offer is applied. An offer is
                    available for cash-paying patients.{' '}
                    <InlineModal content={termsConditionsJSX()}>
                      Click here
                    </InlineModal>{' '} for
                    pharmacist instructions, eligibility instructions, and full terms and
                    conditions.
                  </span>
                </Stack>
              </Stack>
              <Stack horizontalAlign="center" style={{ paddingTop: '3rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    maxWidth: 'auto',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    border: `1px solid ${theme.palette.green}`,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    fontSize: '16px',
                    font: 'Centra No2',
                    fontWeight: '700',
                    minWidth: 'fit-content',
                    fontFamily: 'Centra No2 Bold',
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => window.open('https://www.xelstrym.com/')}
                >
                  Visit xelstrym.com
                </PrimaryButton>
              </Stack>
            </Stack>
          ) : (
            <Stack
              horizontalAlign="center"
              style={{
                textAlign: 'center',
                maxWidth: '70%',
                height: 'fit-content',
                background:
                  'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
                color: '#333',
                padding: '2rem',
                marginBottom: '2rem',
                marginTop: '1rem',
                fontSize: '18px',
                fontWeight: '400',
              }}
            >
              <Stack
                horizontalAlign="center"
                style={{
                  paddingTop: '2rem',
                }}
              >
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '44px',
                    color: '#0047BB',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  No Noven C.A.N.™ Partner Pharmacies
                  <br />
                  in your area—but you can still save!
                </span>
                <span style={{ width: '70%', padding: '1.5rem 0.5rem' }}>
                  At this time, there is not a Noven C.A.N. Partner Pharmacy within 50 miles of your
                  ZIP code. There may be additional Partner Pharmacies available further from you
                  that may offer delivery and/or shipping of your prescription. Visit our{' '}
                  <a
                    href="https://www.xelstrym.com/pharmacy-finder"
                    style={{ color: '#509E2F', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner Pharmacy Finder
                  </a>{' '}
                  to learn more.
                  <br />
                  <br />
                  In the meantime, you can still take advantage of our copay savings offer below at
                  any US retail pharmacy. If a Partner Pharmacy becomes available in your area in
                  the future, this card may be used at that pharmacy for potential additional
                  savings.
                </span>
              </Stack>

              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{
                  border: '1px solid #509E2F',
                  width: '95%',
                  borderRadius: '15px',
                  background: '#ffffff',
                  marginTop: '1rem',
                }}
              >
                <Stack
                  verticalAlign="center"
                  style={{
                    width: '100%',
                    background: '#509E2F',
                    height: 'fit-content',
                    color: '#ffffff',
                    fontSize: '34px',
                    fontWeight: '700',
                    borderRadius: '13px 13px 0 0',
                    fontFamily: 'Centra No2 Bold',
                  }}
                >
                  <span style={{ paddingTop: '0.8rem', paddingBottom: '0.8rem' }}>
                    {/* Non-partner pharmacy savings offer */}
                    XELSTRYM Copay Savings Offer
                  </span>
                </Stack>

                <Stack style={{ padding: '1.5rem' }}>
                  <span>
                    At a{' '}
                    <span style={{ fontFamily: 'Centra No2 Bold' }}>
                      non-NOVEN C.A.N. Partner Pharmacy, insured patients will pay as little as $50
                      for each <br />
                      XELSTRYM prescription.*
                    </span>
                    <br />
                    <br />
                    Please save this card to your digital wallet by scanning the QR code below, or
                    by downloading and <br />
                    printing it. Your pharmacy will ask you for the information on the card.
                  </span>
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    style={{
                      minWidth: '85%',
                      paddingLeft: '2rem',
                      paddingBottom: '1.5rem',
                      paddingTop: '2rem',
                    }}
                  >
                    <img
                      src={enrollment?.ezWalletResponse?.copayCardImageUrl}
                      style={{ height: '190px', width: '305px', paddingRight: '3rem' }}
                    />
                    <Stack
                      horizontalAlign="center"
                      verticalAlign="center"
                      style={{ paddingRight: '1rem' }}
                    >
                      <img
                        src={qrCode(enrollment)}
                        style={{ height: '95px', width: '95px', paddingBottom: '1rem' }}
                      />
                      <span style={{ color: '#333', fontSize: '16px' }}>
                        <span style={{ fontFamily: 'Centra No2 Bold' }}> Scan </span> the QR code
                        and add <br />
                        your card to your digital wallet
                      </span>
                    </Stack>
                    <span style={{ color: '#509E2F', paddingRight: '2rem', alignSelf: 'center' }}>
                      <span style={{ fontFamily: 'Centra No2 Bold' }}>OR</span>
                    </span>
                    <Stack verticalAlign="end">
                      <PrimaryButton
                        className={mergeStyles({
                          height: '3rem',
                          maxWidth: '200px',
                          borderRadius: '5px',
                          boxShadow: theme.effects.elevation8,
                          border: `1px solid ${theme.palette.green}`,
                          color: theme.palette.white,
                          background: theme.palette.green,
                          minWidth: 'fit-content',
                          marginTop: '2.8rem',
                          ':hover': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                          ':active': {
                            background: theme.palette.green,
                            border: `1px solid ${theme.palette.green}`,
                            color: theme.palette.white,
                          },
                        })}
                        onClick={() => downloadPDF(enrollment)}
                      >
                        {loading ? (
                          <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{
                              height: 'inherit',
                              width: 'inherit',
                              color: theme.palette.white,
                              background: theme.palette.green,
                            }}
                          >
                            <Spinner />
                          </Stack>
                        ) : (
                          <>
                            <span
                              style={{
                                marginTop: '4px',
                                marginLeft: '4px',
                                color: theme.palette.white,
                                background: theme.palette.green,
                                fontFamily: 'Centra No2 Bold',
                              }}
                            >
                              Download card
                            </span>
                            <img
                              src={DownloadCardLogo}
                              style={{
                                float: 'left',
                                width: '18px',
                                height: '18px',
                                paddingLeft: '8px',
                              }}
                            />
                          </>
                        )}
                      </PrimaryButton>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                          color: '#333',
                          marginTop: '0rem',
                        }}
                      >
                        <br />
                        Click the{' '}
                        <span style={{ fontFamily: 'Centra No2 Bold' }}>“Download card”</span>
                        <br />
                        button to save as a PDF
                      </span>
                    </Stack>
                  </Stack>
                  <span style={{ textAlign: 'center', fontSize: '16px' }}>
                    <span style={{ fontFamily: 'Centra No2 Bold' }}>NOTE:</span> As long as you
                    provided your mobile number during enrollment, your card information will also
                    be sent via SMS.
                    <br />
                    <br />
                    *Copay savings offer is available for commercially insured patients. At Noven
                    C.A.N. Partner Pharmacies, eligible patients pay as little as $25 for each
                    XELSTRYM prescription. At non-Partner Pharmacies, eligible patients pay as
                    little as $50 for each XELSTRYM prescription. Patient is responsible for any
                    remaining balance due after copay savings offer is applied. An offer is
                    available for cash-paying patients.{' '}
                    <InlineModalMobile content={termsConditionsJSX()}>
                      Click here
                    </InlineModalMobile>{' '} for
                    pharmacist instructions, eligibility instructions, and full terms and
                    conditions.
                  </span>
                </Stack>
              </Stack>
              <Stack horizontalAlign="center" style={{ paddingTop: '3rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    maxWidth: 'auto',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    border: `1px solid ${theme.palette.green}`,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    fontSize: '16px',
                    font: 'Centra No2',
                    fontWeight: '700',
                    minWidth: 'fit-content',
                    fontFamily: 'Centra No2 Bold',
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => window.open('https://www.xelstrym.com/')}
                >
                  Visit xelstrym.com
                </PrimaryButton>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
