/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import './App.css';
import { XelstrymWeb } from './xelstrym/Xelstrym.web';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TermsAndConditions } from './xelstrym/TermsAndConditions';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import requireContext from 'require-context.macro';
import { PatientLetter } from './xelstrym/PatientLetter';

function App() {
  const [isLoading, setIsLoading] = React.useState(false);
  const ASSETS = requireContext('./assets', false, /\.(png|svg|woff2)$/);
  const keys = ASSETS.keys();
  console.log(ASSETS.keys());

  useEffect(() => {
    const importAll = async () => {
      const assets = await Promise.all(
        keys.map(async (key: string) => {
          const module = await import(`${ASSETS.resolve(key)}`);
          return module.default || module;
        })
      );

      await cacheAssets(assets);
    };

    importAll();
  }, []);

  const cacheAssets = async (assets: string[]) => {
    const promises = assets.map((asset) => {
        return new Promise<void>((resolve, reject) => { // Explicitly typing as Promise<void>
            if (asset.endsWith('.svg') || asset.endsWith('.png')) {
                const img = new Image();
                img.src = asset;
                img.onload = () => resolve();
                img.onerror = reject;
            } else if (asset.endsWith('.woff2')) {
                // Preload fonts using FontFace
                const fontName = asset.split('/').pop()?.split('.')[0] || '';
                console.log(fontName);
                const font = new FontFace(fontName, `url(${asset})`);
                font.load()
                    .then(() => {
                        document.fonts.add(font);
                        resolve();
                    })
                    .catch(reject);
            } else {
                // Handle other types of assets
                resolve(); // Now valid
            }
        });
    });

    await Promise.all(promises);
    setIsLoading(false);
};


  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isLoading ? (
              <Stack style={{ height: '100vh', width: '100vw' }}>
                <Spinner style={{ height: '100vh', width: '100vw' }} size={SpinnerSize.large} />
              </Stack>
            ) : (
              <XelstrymWeb />
            )
          }
        />
        <Route path="/networktermsandconditions" element={<TermsAndConditions />} />
        <Route path="/useanywheretermsandconditions" element={<TermsAndConditions />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/patient-letter" element={<PatientLetter />} />
        <Route path="*" element={<></>} />
      </Routes>
    </Router>
  );
}

export default App;
