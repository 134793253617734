import { Stack } from '@fluentui/react';
import React, { Dispatch, SetStateAction, useState } from 'react';

export const ButtonGroup = (props: {
  children: React.ReactNode;
  onChange: Dispatch<SetStateAction<string>>;
  isMobile: boolean;
}) => {
  const { children, onChange, isMobile } = props;
  const [noClicked, setNoClicked] = useState(false);
  const [yesClicked, setYesClicked] = useState(false);

  const handleClick = (input: string) => {
    if (input === 'yes') {
      onChange('yes');
      setYesClicked(true);
      setNoClicked(false);
    } else {
      onChange('no');
      setNoClicked(true);
      setYesClicked(false);
    }
  };
  return (
    <Stack
      horizontalAlign="center"
      style={{
        paddingTop: '2rem',
        width: `${isMobile ? '90%' : '70%'}`,
        fontFamily: 'Centra No2',
        color: '#333',
        fontSize: `${isMobile ? '16px' : '18px'}`,
        fontWeight: '400',
        marginTop: 0,
        textAlign: 'center',
      }}
    >
      <span style={{ width: `${isMobile ? '100%' : '60%'}` }}>{children}</span>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        style={{ padding: '0.8rem', width: `${isMobile ? '65%' : '25%'}`, color: '#333' }}
      >
        <Stack horizontal horizontalAlign="center" verticalAlign="center">
          <div
            style={{
              cursor: 'pointer',
              height: '25px',
              width: '25px',
              border: '2px solid #1C4DBD',
              borderRadius: '50%',
              padding: '2px',
              background: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              id="1"
              style={{
                background: yesClicked ? '#509E2F' : '#ECF6FF',
                cursor: 'pointer',
                height: '25px',
                width: '25px',
                border: 'none',
                borderRadius: '50%',
              }}
              onClick={() => handleClick('yes')}
            ></button>
          </div>
          <p style={{ marginLeft: '0.5rem' }}>Yes</p>
        </Stack>
        <Stack horizontal horizontalAlign="center" verticalAlign="center">
          <div
            style={{
              cursor: 'pointer',
              height: '25px',
              width: '25px',
              border: '2px solid #1C4DBD',
              borderRadius: '50%',
              padding: '2px',
              background: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
            }}
          >
            <button
              id="2"
              style={{
                background: noClicked ? '#509E2F' : '#ECF6FF',
                cursor: 'pointer',
                height: '25px',
                width: '25px',
                border: 'none',
                borderRadius: '50%',
              }}
              onClick={() => handleClick('no')}
            ></button>
          </div>
          <p style={{ marginLeft: '0.5rem' }}>No</p>
        </Stack>
      </Stack>
    </Stack>
  );
};
