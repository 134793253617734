import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import XelstrymTopRightLogo from '../assets/xelstrym-top-right-logo-new.png';

export const Header = (props: { isMobile: boolean }) => {
  const { isMobile } = props;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const menuStyles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '26px',
      height: '18px',
      left: '12px',
      top: '26px',
    },
    bmBurgerBars: {
      background: '#ffffff',
      borderRadius: '15px',
    },
    bmBurgerBarsHover: {
      background: '#ffffff',
    },
    bmCrossButton: {
      minHeight: '36px',
      minWidth: '48px',
      position: 'absolute',
      left: '-15px',
      top: '10px',
      background: '#ffffff',
    },
    bmCross: {
      background: '#0047BB',
      fontSize: '48px',
      height: '36px',
      fontWeight: 'bold',
      marginLeft: '10px',
      WebkitTextStroke: '2px #0047BB',
    },
    bmMenuWrap: {
      position: 'fixed',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      height: 'auto',
      borderRadius: '10px',
    },
    bmMenu: {
      background: '#ffff',
      padding: '1rem',
      fontSize: '16px',
      height: 'auto',
      overflowY: 'hidden',
      borderRadius: '0 0 10px 10px',
      overflow: 'auto',
    },
    bmMorphShape: {
      fill: '#509E2F',
    },
    bmItemList: {
      color: '#509E2F',
      paddingTop: '2.5rem',
      WebkitTextStroke: '0.5px #509E2F',
    },
    bmItem: {
      color: '#ffffff',
      display: 'block',
      width: '100%',
      marginTop: '0.3rem',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;
      const scrollPercent = (scrollHeight * 100) / pageHeight;

      if (scrollPercent > 20) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component is unmounted
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Menu
            isOpen={isMenuOpen}
            styles={menuStyles}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
          >
            <hr style={{ border: '1px solid #bababa', width: '100%', padding: 0, margin: 0 }}></hr>
            <a
              href="https://www.xelstrym.com/pdfs/medication-guide.pdf"
              style={{
                color: 'inherit',
                marginRight: '3rem',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '400',
                padding: '0.5rem',
              }}
              target="_blank"
              rel="noreferrer"
            >
              MEDICATION GUIDE
            </a>
            <hr style={{ border: '1px solid #bababa', width: '100%', padding: 0, margin: 0 }}></hr>
            <a
              href="https://www.xelstrym.com/pdfs/prescribing-information.pdf"
              style={{
                color: 'inherit',
                marginRight: '3rem',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '400',
                padding: '0.5rem',
              }}
              target="_blank"
              rel="noreferrer"
            >
              FULL PRESCRIBING INFORMATION, INCLUDING BOXED WARNING
            </a>
            <hr style={{ border: '1px solid #bababa', width: '100%', padding: 0, margin: 0 }}></hr>
            <a
              href="https://www.xelstrym.com/pdfs/instructions-for-use.pdf"
              style={{
                color: 'inherit',
                marginRight: '3rem',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '400',
                padding: '0.5rem',
              }}
              target="_blank"
              rel="noreferrer"
            >
              INSTRUCTIONS FOR USE
            </a>
            <hr style={{ border: '1px solid #bababa', width: '100%', padding: 0, margin: 0 }}></hr>
            <a
              href="https://www.xelstrym.com/"
              style={{
                color: 'inherit',
                marginRight: '3rem',
                textDecoration: 'none',
                fontSize: '16px',
                fontWeight: '400',
                padding: '0.5rem',
              }}
              target="_blank"
              rel="noreferrer"
            >
              PATIENT WEBSITE
            </a>
          </Menu>
          <img
            src={XelstrymTopRightLogo}
            onClick={() => {
              // redirect to home page
              window.location.href = '/';
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              // translate height with screen size
              height: 'auto',
              width: '40%',
              maxWidth: '200px',
              cursor: 'pointer',
            }}
          />
        </>
      ) : (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{
            background: '#509E2F',
            height: '40px',
            width: '100%',
          }}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            style={{
              width: '90%',
              height: 'inherit',
              color: '#Ffffff',
              paddingTop: '0rem',
              fontSize: '14px',
              fontWeight: '400',
              marginRight: '18%',
            }}
          >
            <a
              href="https://www.xelstrym.com/pdfs/medication-guide.pdf"
              style={{
                color: 'inherit',
                marginRight: '2.5rem',
                textDecoration: 'none',
              }}
              target="_blank"
              rel="noreferrer"
            >
              Medication Guide {'>'}
            </a>
            <a
              href="https://www.xelstrym.com/pdfs/prescribing-information.pdf"
              style={{ color: 'inherit', marginRight: '2.5rem', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Full Prescribing Information, Including BOXED WARNING {'>'}
            </a>
            <a
              href="https://www.xelstrym.com/pdfs/instructions-for-use.pdf"
              style={{ color: 'inherit', marginRight: '2.5rem', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Instructions for Use {'>'}
            </a>

            <a
              href="https://www.xelstrym.com/"
              style={{ color: 'inherit', marginRight: '2.5rem', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Patient Website {'>'}
            </a>
          </Stack>
          <img
            src={XelstrymTopRightLogo}
            onClick={() => {
              // redirect to home page
              window.location.href = '/';
            }}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              // translate height with screen size
              height: 'auto',
              width: '20vw',
              cursor: 'pointer',
            }}
          />
        </Stack>
      )}
    </>
  );
};
