/* eslint-disable react/no-unescaped-entities */
import { initializeIcons, Stack, ThemeProvider } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import { AppController } from '../components/AppController';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { XelstrymTheme } from './Xelstrym.theme';

initializeIcons();

export const termsConditionsJSX = () => (
  <>
    <div>
      <span style={{ fontFamily: 'Centra No2 Bold' }}>Patient Offer:</span>
      <ul>
        <li>
          <span style={{ fontFamily: 'Centra No2 Bold' }}>
            Utilizing a Noven C.A.N.™ Partner Pharmacy:
          </span>{' '}
          Commercially insured patients{' '}
          <span style={{ fontFamily: 'Centra No2 Bold' }}>
            pay as little as $25 for each XELSTRYM prescription;
          </span>{' '}
          Cash-paying patients receive up to $200 off each prescription
        </li>
        <li>
          <span style={{ fontFamily: 'Centra No2 Bold' }}>Utilizing a Non-Partner Pharmacy:</span>{' '}
          Commercially insured patients pay as little as $50 for each XELSTRYM prescription;
          Cash-paying patients receive up to $100 off each prescription
        </li>
        <li>Offer limited to one use per 30-day supply</li>
      </ul>
    </div>
    <div>
      <span style={{ fontFamily: 'Centra No2 Bold' }}>
        A Quick Tip for Patients with Insurance:
      </span>
      <p>
        Your provider chose to prescribe XELSTRYM. Although XELSTRYM is available with most
        insurances, some plans may require additional information to be submitted by your provider
        before the plan will approve covering some or all of the cost for XELSTRYM. This form from
        your insurance plan is often called a Prior Authorization or “PA”.
      </p>
      <p>
        Once your prescription is submitted at the pharmacy, your pharmacist will confirm if your
        plan requires this form.
        <span style={{ fontFamily: 'Centra No2 Bold' }}>
          {' '}
          If so, work with your provider and pharmacist to submit all required information as soon
          as possible so your plan will approve the form and provide you the maximum cost assistance
          possible along with this offer to reduce the amount you’re asked to pay out-of-pocket for
          each prescription of XELSTRYM.
        </span>
      </p>
    </div>

    <div>
      <p>Questions regarding the XELSTRYM Copay Savings Program? Please call 1-833-483-2178.</p>
    </div>

    <div>
      <p>
        <span style={{ fontFamily: 'Centra No2 Bold' }}>Pharmacist:</span> When applying this offer,
        you are certifying that you have not submitted a claim for reimbursement under any federal,
        state, or other government programs for this prescription. Participation in this program
        must comply with all applicable laws and regulations as a pharmacy provider. By
        participating in this program, you are certifying that you will comply with the terms and
        conditions described below.{' '}
      </p>
    </div>

    <div>
      <span style={{ fontFamily: 'Centra No2 Bold' }}>
        Pharmacist Instructions for Eligible Third-Party Payer Patients:
      </span>
      <ul>
        <li>
          First, submit the primary claim to your patient’s third-party payer (insurance provider)
        </li>
        <li>
          Next, submit the balance due to Pharmacy Data Management, Inc. (PDMI) as a secondary payer
          using secondary payer coordination of benefits (COB) with patient responsibility amount
          and a valid Other Coverage Code, (e.g., 8 or 3)
        </li>
        <li>
          Depending on coverage, patients may pay as little as $25 and the card pays up to a maximum
          allowable benefit
        </li>
        <li>
          The patient is responsible for any remaining balance due after savings offer has been
          applied
        </li>
        <li>Reimbursement will be received from PDMI</li>
      </ul>
    </div>

    <div>
      <span>
        <span style={{ fontFamily: 'Centra No2 Bold' }}>
          Pharmacist Instructions for Cash-Paying Patients:
        </span>
      </span>
      <ul>
        <li>
          Submit the claim to PDMI as primary payer using a valid Other Coverage Code (e.g., 1)
        </li>
        <li>
          The patient pay amount submitted will be reduced by up to $200 for a quantity of up to 30
          patches
        </li>
        <li>Reimbursement will be received from PDMI</li>
      </ul>
    </div>

    <div>
      <p>
        For questions regarding PDMI online processing of the copay offer, please call the PDMI Help
        Desk at 1-316-219-4802.
      </p>
    </div>

    <div>
      <p>
        <span style={{ fontFamily: 'Centra No2 Bold' }}>Terms and Conditions:</span> This program
        provides non-government copay assistance with out-of-pocket costs for eligible patients.
        Offer may only be used by eligible residents of the U.S. at participating pharmacies and may
        not be redeemed at government-subsidized clinics. Patient age or insurance restrictions may
        apply.
      </p>
      <p>
        Offer is not transferrable. No substitutions are permitted. Offer eligible only with valid
        prescription, has no cash value and cannot be combined with any free trial, discount,
        prescription savings card, or other offer. This offer is not insurance. This copay savings
        offer is only valid for commercially insured and cash-paying patients. It is not valid for
        prescriptions eligible to be reimbursed in whole or in part by Medicaid, Medicare (including
        Medicare Advantage and Part D plans), or any other federally or state funded healthcare
        benefit program, or by commercial plans or other health or pharmacy benefit programs that
        reimburse for entire cost of the prescription drug or prohibit offer’s use. Medicare Part D
        enrollees who are in the prescription drug coverage gap are not eligible for offer. Void
        where prohibited. It is illegal to sell, purchase, trade, or counterfeit the offer.
      </p>
      <p>
        Patient, pharmacist, and prescriber agree not to seek reimbursement for all or any part of
        the benefit received by patient through the offer. Certain information pertaining to use of
        the offer will be shared with Noven Pharmaceuticals, Inc., the sponsor of the offer, and its
        affiliates. The information disclosed will include the patient copay ID, pharmacy
        demographics, prescriber information, and details relating to the claim, such as copay
        amount, insurance details, and therapy received. For more information, please see the Noven
        Pharmaceuticals, Inc. privacy policy, located at{' '}
        <span
          style={{ color: '#509E2F', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => window.open('https://www.noven.com/privacy-policy/', '_blank')}
        >
        https://www.noven.com/privacy-policy/
        </span>
        .
      </p>
      <p>
        Noven Pharmaceuticals, Inc. reserves the right to rescind, revoke, or amend the offer at any
        time without notice.
      </p>
      <p>
        <span style={{ fontFamily: 'Centra No2 Bold' }}>
          For product questions, please call Noven Pharmaceuticals, Inc. at 1-800-455-8070.
        </span>
      </p>
    </div>
    <br />
    <br />
  </>
);

export const XelstrymWeb = () => {
  const [isMobile, setIsMobile] = useState(false);

  const xelstrymTheme = {
    ...XelstrymTheme,
    fonts: {
      ...XelstrymTheme.fonts,
    },
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const device = parser.getDevice().type;

    const isMobileDevice = device === 'mobile';
    const isSmallScreen = window.innerWidth <= 1000;

    setIsMobile(isMobileDevice || isSmallScreen);
  }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider
      theme={xelstrymTheme}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        background: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
      }}
    >
      <Header isMobile={isMobile} />
      <Stack
        verticalAlign="center"
        style={{
          maxWidth: '100%',
        }}
      >
        <AppController isMobile={isMobile} />
      </Stack>

      <Footer isMobile={isMobile} />
    </ThemeProvider>
  );
};
